import { isMemoFeedItem, isPostFeedItem, MemoFeedItem, PostFeedItem } from '@commonstock/common/src/api/feed'
import { LikeTypes } from '@commonstock/common/src/api/like'
import { ShareType } from '@commonstock/common/src/api/link'
import { ReportContentType } from '@commonstock/common/src/api/report'
import { destructMemoFeedItem } from 'src/scopes/memo/utils'
import { destructPostFeedItem } from 'src/scopes/posts/utils'

export function destructContentFeedItem(feedItem: PostFeedItem | MemoFeedItem) {
  let content, user, reason_code, reason_text, mentionAttachments, linkedTrades
  if (isPostFeedItem(feedItem)) {
    ;({ post: content, user, reason_code, reason_text, mentionAttachments, linkedTrades } = destructPostFeedItem(
      feedItem
    ))
  } else if (isMemoFeedItem(feedItem)) {
    ;({ memo: content, user, reason_code, reason_text, mentionAttachments, linkedTrades } = destructMemoFeedItem(
      feedItem
    ))
  }
  return { content, user, reason_code, reason_text, mentionAttachments, linkedTrades }
}

export function getContentShareType(feedItem: PostFeedItem | MemoFeedItem) {
  if (isPostFeedItem(feedItem)) {
    return ShareType.POST
  } else if (isMemoFeedItem(feedItem)) {
    return ShareType.MEMO_PLUS
  }
  return ShareType.MEMO_PLUS
}

export function getContentReportType(feedItem: PostFeedItem | MemoFeedItem) {
  if (isPostFeedItem(feedItem)) {
    return ReportContentType.Post
  } else if (isMemoFeedItem(feedItem)) {
    return ReportContentType.Memo
  }
  return ReportContentType.Memo
}

export function getContentLikeType(feedItem: PostFeedItem | MemoFeedItem) {
  if (isPostFeedItem(feedItem)) {
    return LikeTypes.POST
  } else if (isMemoFeedItem(feedItem)) {
    return LikeTypes.MEMO_PLUS
  }
  return LikeTypes.MEMO_PLUS
}
